import styled, { css } from "styled-components";
import { Colors, fontSizes } from "./theme";
import { FiChevronUp, FiChevronDown } from "react-icons/fi";
import Select, { StylesConfig, components } from "react-select";
import React, { useEffect, useState } from "react";
import { OptionType } from "../interfaces/settings.interface";

interface ICustomSelect {
  options: OptionType[];
  styles?: StylesConfig<any, false>;
  placeholder?: string;
  isMenuOpen?: boolean;
  isSearchable?: boolean;
  isClearable?: boolean;
  dropDownColor?: string;
  setIsMenuOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  onSelect?: (opt: OptionType) => void;
  prefillId?: string | number;
  menuPortalTarget?: HTMLElement;
  initialValue?: OptionType;
}

const { primaryColor, white, lightGrey } = Colors;
const { base } = fontSizes;

interface TextProps {
  fontSize?: string;
  color?: string;
  fontWeight?: string;
  marginBottom?: string;
  margin?: string;
  padding?: string;
  noWrap?: boolean;
  width?: string;
  maxWidth?: string;
  textTransform?: "capitalize" | "upppercase" | "lowercase" | "match-auto" | "none";
  textOverflow?: "ellipsis" | "clip" | "inherit" | "initial" | "revert" | "unset";
  textDecoration?:
    | "none"
    | "underline"
    | "overline"
    | "line-through"
    | "underline overline"
    | "underline line-through"
    | "overline line-through"
    | "underline overline line-through";
  cursor?:
    | "auto"
    | "default"
    | "none"
    | "context-menu"
    | "help"
    | "pointer"
    | "progress"
    | "wait"
    | "cell"
    | "crosshair"
    | "text"
    | "vertical-text"
    | "alias"
    | "copy"
    | "move"
    | "no-drop"
    | "not-allowed"
    | "grab"
    | "grabbing"
    | "all-scroll"
    | "col-resize"
    | "row-resize"
    | "n-resize"
    | "e-resize"
    | "s-resize"
    | "w-resize"
    | "ne-resize"
    | "nw-resize"
    | "se-resize"
    | "sw-resize"
    | "ew-resize"
    | "ns-resize"
    | "nesw-resize"
    | "nwse-resize";
  mdResponsive?: string;
  smResponsive?: string;
}

export const Text = styled.p<TextProps>`
  font-size: ${({ fontSize }) => fontSize ?? fontSizes.small};
  color: ${({ color }) => color || "#444"};
  font-weight: ${({ fontWeight }) => fontWeight};
  margin: ${({ margin }) => margin ?? "0px"};
  margin-bottom: ${({ marginBottom }) => marginBottom ?? "0px"};
  padding: ${({ padding }) => padding ?? "0px"};
  width: ${({ width }) => width};
  ${({ textTransform }) => textTransform && "text-transform: " + textTransform};
  ${({ textOverflow }) => textOverflow && "text-overflow: " + textOverflow};
  ${({ textDecoration }) => textDecoration && "text-decoration: " + textDecoration};
  ${({ cursor }) => cursor && "cursor: " + cursor};

  ${({ noWrap }) =>
    noWrap &&
    `
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}

  @media (max-width: 770px) {
    ${({ mdResponsive }) => css`
      ${mdResponsive}
    `}
  }

  @media (max-width: 430px) {
    ${({ smResponsive }) => css`
      ${smResponsive}
    `}
  }
`;

interface SpanProps {
  fontSize?: string;
  color?: string;
  fontWeight?: string;
  margin?: string;
  noWrap?: boolean;
  width?: string;
  background?: string;
  textAlign?: string;
  lineThrough?: boolean;
  lineThroughSize?: string;
  cursor?: string;
  opacity?: number;
  fontStyle?: string;
  border?: string;
  padding?: string;
  height?: string;
  borderRadius?: string;
  smResponsive?: string;
  mdResponsive?: string;
  lgResponsive?: string;
  textTransform?: string;
}

export const Span = styled.span<SpanProps>`
  font-size: ${({ fontSize }) => fontSize ?? "inherit"};
  font-style: ${({ fontStyle }) => fontStyle ?? "none"};
  color: ${({ color }) => color ? `${color} !important` : "inherit"};
  font-weight: ${({ fontWeight }) => fontWeight};
  margin: ${({ margin }) => margin};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  text-align: ${({ textAlign }) => textAlign};
  cursor: ${({ cursor }) => cursor};
  opacity: ${({ opacity }) => opacity};
  border: ${({ border }) => border || "unset"};
  padding: ${({ padding }) => padding || "unset"};
  background-color: ${({ background }) => background || "unset"};
  border-radius: ${({ borderRadius }) => borderRadius || "unset"};
  text-transform: ${({ textTransform }) => textTransform || "unset"};

  ${({ noWrap }) =>
    noWrap &&
    `
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}

  ${({ lineThrough, lineThroughSize }) =>
    lineThrough &&
    `text-decoration: line-through;
      text-decoration-thickness: ${lineThroughSize ?? "1px"}
  `};

  @media screen and (max-width: 770px) {
    ${({ mdResponsive }) => css`
      ${mdResponsive}
    `}
  }

  @media screen and (max-width: 430px) {
    ${({ smResponsive }) => css`
      ${smResponsive}
    `}
  }

  @media screen and (max-width: 930px) {
    ${({ lgResponsive }) => css`
      ${lgResponsive}
    `}
  }
`;

interface ButtonProps {
  backgroundColor?: string;
  width?: string;
  height?: string;
  borderRadius?: string;
  borderColor?: string;
  borderSize?: string;
  padding?: string;
  margin?: string;
  notFilled?: boolean;
  color?: string;
  disable?: boolean;
  bgHover?: string;
  actionBtn?: boolean;
  alignItems?: string;
  justifyContent?: string;
  hoverColor?: string;
  fontSize?: string;
}

export const Button = styled.button<ButtonProps>`
  text-decoration: none;
  font-style: normal;
  font-weight: normal;
  font-size: ${({ fontSize }) => fontSize ?? base};
  line-height: 1rem;
  color: ${({ color }) => color ?? white};
  background-color: ${({ backgroundColor, disable, notFilled }): string =>
    disable ? lightGrey : notFilled ? "transparent" : backgroundColor ?? primaryColor};
  border: ${({ borderSize }): string => borderSize ?? "1px"} solid
    ${({ backgroundColor, borderColor, disable }): string =>
      disable ? lightGrey : borderColor ?? backgroundColor ?? primaryColor};
  box-sizing: border-box;
  border-radius: 0.5rem;
  /* border-radius: ${({ borderRadius }) => borderRadius ?? "0.5rem"}; */
  padding: ${({ padding }) => padding ?? ".75rem 1.25rem"};
  width: ${({ width }) => width};
  height: ${({ height }) => height ?? "3.125rem"};
  display: flex;
  align-items: ${({ alignItems }) => alignItems ?? "center"};
  justify-content: ${({ justifyContent }) => justifyContent ?? "center"};
  text-align: center;
  margin: ${({ margin }) => margin ?? "unset"};
  cursor: ${({ disable }) => (disable ? "not-allowed" : "pointer")};
  transition: ease-in-out all 0.3s;

  ${({ notFilled, borderColor, bgHover, backgroundColor, disable, hoverColor }) =>
    disable
      ? ""
      : notFilled
      ? `&:hover {
    background-color: ${bgHover ?? borderColor ?? primaryColor};
    color: ${hoverColor ?? white};
  }`
      : `&:hover {
    background-color: ${bgHover ?? backgroundColor ?? primaryColor};
    border: 1px solid ${bgHover ?? backgroundColor ?? primaryColor};
  }`}
`;

interface FlexProps {
  justifyContent?: string;
  alignItems?: string;
  alignSelf?: string;
  width?: string;
  minWidth?: string;
  direction?: string;
  margin?: string;
  flexFlow?: string;
  padding?: string;
  gap?: string;
  bg?: string;
  borderRadius?: string;
  boxShadow?: string;
  overflow?: string;
  zIndex?: number;
  height?: string;
  fontSize?: string;
  cursor?: string;
  opacity?: boolean;
  shadow?: boolean;
  minHeight?: string;
  maxHeight?: string;
  display?: string;
  border?: string;
  transition?: string;
  flexWrap?: string;
  hover?: boolean;
  columnGap?: string;
  color?: string;
  hideScrollbar?: boolean;
  overflowX?: string;
  overflowY?: string;
  maxWidth?: string;
  disabled?: boolean;
  position?: string;
  hoverBg?: string;
  alignText?: string;
  fontWeight?: string;
  smResponsive?: string;
  mdResponsive?: string;
  lgResponsive?: string;
  borderBottom?: string;
  pointerEvents?: "auto" | "none";
}

export const Flex = styled.div<FlexProps>`
  display: ${({ display }) => display ?? "flex"};
  justify-content: ${({ justifyContent }) => justifyContent ?? "unset"};
  align-items: ${({ alignItems }) => alignItems ?? "unset"};
  width: ${({ width }) => width || "100%"};
  min-width: ${({ minWidth }) => minWidth};
  position: ${({ position }) => position};
  max-width: ${({ maxWidth }) => maxWidth};
  height: ${({ height }) => height ?? "unset"};
  min-height: ${({ minHeight }) => minHeight ?? "unset"};
  max-height: ${({ maxHeight }) => maxHeight ?? "unset"};
  flex-direction: ${({ direction }) => direction ?? "row"};
  align-self: ${({ alignSelf }) => alignSelf ?? "unset"};
  margin: ${({ margin }) => margin};
  flex-flow: ${({ flexFlow }) => flexFlow};
  padding: ${({ padding }) => padding ?? "0px"};
  font-size: ${({ fontSize }) => fontSize ?? ".875rem"};
  font-weight: ${({ fontWeight }) => fontWeight ?? "300"};
  gap: ${({ gap }) => gap ?? "0px"};
  column-gap: ${({ columnGap }) => columnGap};
  flex-wrap: ${({ flexWrap }) => flexWrap ?? "unset"};
  background-color: ${({ bg }) => bg};
  color: ${({ color }) => color};
  border-radius: ${({ borderRadius }) => borderRadius ?? "0px"};
  border: ${({ border }) => border ?? "unset"};
  /* border-bottom: ${({ borderBottom }) => borderBottom ?? "unset"}; */
  box-shadow: ${({ boxShadow, shadow }) =>
    shadow ? "rgba(100, 100, 111, 0.2) 0px 7px 1.25rem 0px" : boxShadow ?? "none"};
  overflow: ${({ overflow }) => overflow};
  overflow-x: ${({ overflowX }) => overflowX};
  overflow-y: ${({ overflowY }) => overflowY};
  z-index: ${({ zIndex }) => zIndex ?? "unset"};
  cursor: ${({ cursor }) => cursor ?? "unset"};
  opacity: ${({ opacity, disabled }) => (disabled || opacity ? "0.5" : "unset")};
  transition: ${({ transition }) => transition ?? "unset"};
  text-align: ${({ alignText }) => alignText ?? "unset"};
  pointer-events: ${(props: FlexProps) => (props.disabled ? "none" : "unset")};
  box-sizing: border-box;
  pointer-events: ${({ pointerEvents }) => pointerEvents || "auto"};

  
  -ms-overflow-style: none;
  scrollbar-width: 0.3125rem;

  ::-webkit-scrollbar {
    background-color: #fff;
    width: 0.3125rem;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: ${Colors.primaryColor};
  }

  :hover {
    ${({ hover, hoverBg }) =>
      hover &&
      `
      opacity: 0.8;
      background-color: ${hoverBg};
  `}
  }
  ${({ hideScrollbar }) =>
    hideScrollbar &&
    `
     ::-webkit-scrollbar {
    display: none;
  }
  `}

  datalist option {
    padding: 5px;
    cursor: pointer;
  }

  .button-container {
    display: flex;
    gap: 4px;
    justify-content: flex-end;
  }

  @media (max-width: 1024px) {
    ${({ lgResponsive }) => css`
      ${lgResponsive}
    `}
  }

  @media (max-width: 770px) {
    ${({ mdResponsive }) => css`
      ${mdResponsive}
    `}
  }

  @media (max-width: 430px) {
    ${({ smResponsive }) => css`
      ${smResponsive}
    `}
  }
`;

interface CheckButtonProps {
  backgroundColor?: string;
  width?: string;
  height?: string;
  borderRadius?: string;
  borderColor?: string;
  borderSize?: string;
  padding?: string;
  margin?: string;
  color?: string;
  disabled?: boolean;
  alignItems?: string;
  justifyContents?: string;
  selected?: boolean;
  fontSize?: string;
  display?: string;
  minHeight?: string;
  border?: string;
  zIndex?: number;
  maxWidth?: string;
  minWidth?: string;
  gap?: string;
}

export const CheckButton = styled.button<CheckButtonProps>`
  text-decoration: none;
  font-size: ${({ fontSize }) => fontSize || "1rem"};
  color: ${({ color }) => color || "white"};
  background-color: ${({ backgroundColor }) => backgroundColor ?? primaryColor};
  box-sizing: border-box;
  border-radius: ${({ borderRadius }) => borderRadius || "0px"};
  padding: ${({ padding }) => padding || ".75rem 1.2rem"};
  width: ${({ width }) => width};
  height: ${({ height }) => height || "3.125rem"};
  display: ${({ display }) => display || "flex"};
  align-items: ${({ alignItems }) => alignItems ?? "center"};
  justify-content: ${({ justifyContents }) => justifyContents || "center"};
  margin: ${({ margin }) => margin || "unset"};
  border: ${({ border }) => border || "none"};
  gap: ${({ gap }) => gap || "none"};
  cursor: pointer;

  #name {
    font-size: ${fontSizes.base};
  }
`;
interface ICheckBox {
  checked: boolean;
  radius?: string;
  color?: string;
  margin?: string;
}

export const CheckBox = styled.label`
  padding: 0px !important;
  margin: 0px !important;
  border: 1.5px solid ${(props: ICheckBox) => (props.checked ? props.color : "#9EA8B7")};
  height: 1.2rem;
  width: 1.2rem;
  min-width: 1.2rem;
  min-height: 1.2rem;
  border-radius: ${(props: ICheckBox) => props.radius ?? "3px"};
  display: flex;
  align-items: center;
  margin: ${(props: ICheckBox) => props.margin || "0 1.2rem 0 0"};
  justify-content: center;
  cursor: pointer;

  span {
    padding: 0px !important;
    margin: 0px !important;
    display: block;
    width: 0.75rem;
    height: 0.75rem;
    border-radius: ${(props: ICheckBox) => props.radius ?? "3px"};
    background: ${(props: ICheckBox) =>
      props.checked ? props.color ?? primaryColor : "transparent"};
  }
`;

export const ListItem = styled.div<{ opacity?: boolean }>`
  display: flex;
  flex-direction: column;
  background: white;
  border-bottom: 1px solid ${Colors.grey};
  padding: 0.5rem;
  opacity: ${(props) => (props.opacity ? 1 : 0.5)};

  .product-name {
    font-size: ${fontSizes.base};
    font-weight: 500;

    @media (max-width: 430px) {
      font-size: 0.6rem;
    }
  }

  .product-type {
    font-size: ${fontSizes.small};

    @media (max-width: 430px) {
      font-size: ${fontSizes.extraSmall};
    }
  }

  .title-name {
    color: ${Colors.blackLight};
  }

  .bold {
    font-weight: 500;
  }
`;
export const selectStyles = (isError?: boolean, height?: string) => {
  const style = {
    "-ms-overflow-style": "none",
    "scrollbar-width": "0.3125rem",
    "::-webkit-scrollbar": {
      "background-color": "#f6f8fb",
      width: "0.3125rem",
    },
    "::-webkit-scrollbar-thumb": {
      "border-radius": "4px",
      "background-color": "#8196b3",
    },
  };

  return {
    control: (provided: any, state: any) => ({
      ...provided,
      borderRadius: "0.35rem",
      boxShadow: "none",
      border: isError ? "1px solid red" : "none",
      height: "fit-content",
      minHeight: "0px",
      backgroundColor: "#F4F6F9",
      color: Colors.blackLight,
      isolation: "isolate",
      cursor: "pointer",

      "&:hover": {
        outline: state.isFocused ? "1px solid #7D7D7D" : "none",
      },
    }),

    container: (provided: any) => ({
      ...provided,
      boxShadow: "none",
      width: "100%",
      height: "fit-content",
      minHeight: "fit-content",
    }),

    valueContainer: (provided: any) => ({
      ...provided,
      padding: "0 6px",
      minHeight: 0,
      height: height || "2.5rem",
    }),

    singleValue: (provided: any) => ({
      ...provided,
      fontSize: fontSizes.small,
      lineHeight: "1rem",
      color: Colors.black,
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: Colors.blackLight,
      fontSize: fontSizes.small,
      lineHeight: "0.85rem",
    }),
    menu: (provided: any) => ({
      ...provided,
      outline: "none",
      borderRadius: "0.5rem",
      marginTop: "0.5rem",
      paddingTop: "0.5rem",
      ...style,
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      fontSize: fontSizes.base,
      fontWeight: state.isSelected ? "bold" : "normal",
      display: "flex",
      alignItems: "center",
      height: "2rem",
      backgroundColor: state.isSelected ? Colors.primaryColor : "white",
      color: state.isSelected ? Colors.white : Colors.primaryColor,
      "&:hover": {
        cursor: "pointer",
        backgroundColor: `${state.isSelected ? "" : Colors.tabBg}`,
      },
    }),
    indicatorsContainer: (provided: any, state: any) => ({
      ...provided,
      color: "blue",
      height: height || "2.5rem",
      minHeight: "0",
      transform: state.isFocused ? "rotate(0deg)" : "roate(180deg)",
    }),
    indicatorSeparator: (provided: any) => ({
      ...provided,
      display: "none",
    }),
    dropdownIndicator: (provided: any, state: any) => ({
      ...provided,
      transform: state.isFocused ? " " : "rotate(180deg)",
      transitionDuration: "300ms",
      transitionTimingFunction: "ease-in-out",
    }),

    multiValue: (provided: any) => ({
      ...provided,
      backgroundColor: Colors.primaryColor,
      borderRadius: "50px",
      padding: "1px 3px",
      overflow: "hidden",
    }),
    multiValueLabel: (provided: any) => ({
      ...provided,
      color: "white",
      fontWeight: "bold",
    }),
    multiValueRemove: (provided: any) => ({
      ...provided,
      color: "white",
      ":hover": {
        backgroundColor: "darkblue",
        cursor: "pointer",
      },
    }),
  };
};

export const CustomSelect = ({
  options,
  placeholder,
  styles,
  dropDownColor,
  onSelect,
  prefillId,
  menuPortalTarget,
  initialValue,
  isSearchable = true,
  isClearable = false
}: ICustomSelect) => {
  const [value, setValue] = useState<OptionType | undefined>();

  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <FiChevronUp size={22} color={dropDownColor && dropDownColor} />
      </components.DropdownIndicator>
    );
  };

  const handleOnChange = (newValue: OptionType) => {
    setValue(newValue);
    onSelect?.(newValue);
  };

  useEffect(() => {
    if (prefillId && options) {
      const selectedOption = options.find((option) => option.value === prefillId);

      setValue(selectedOption);
    }
  }, [options, prefillId]);

  return (
    <Select
      options={options}
      components={{ DropdownIndicator }}
      value={value || initialValue}
      isSearchable={isSearchable}
      styles={styles}
      placeholder={placeholder}
      onChange={handleOnChange}
      isClearable={isClearable}
      menuPortalTarget={menuPortalTarget || null}
    />
  );
};
